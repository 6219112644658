import {useEffect} from 'react';
import {notification} from 'antd';
import {ArgsProps} from 'antd/es/notification/interface';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import styles from 'features/components/cookie-notice/CookieNotice.module.scss';
import {
  getCookieConsent,
  messageKey,
  setCookieConsent,
} from 'features/components/cookie-notice/CookieNotice.utils';
import {useNavigation} from 'hooks/navigateHooks';
import {Button} from '../button/Button';
import {Text} from '../text/Text';

export function CookieNotice() {
  const {t} = useTranslation();
  const {navigate} = useNavigation();

  useEffect(() => {
    const hasNotAcceptedCookie = !getCookieConsent();
    if (hasNotAcceptedCookie) {
      notification.destroy(messageKey);
      const handleClickAccept = () => {
        setCookieConsent();
        notification.destroy(messageKey);
      };

      const handleClickReadMore = () => navigate('/cookies');

      const props: ArgsProps = {
        message: null,
        description: (
          <div className={styles.notification}>
            <div className={styles.textGroup}>
              <Text variant="small">{t('cookie.usage')}</Text>
            </div>
            <div className={styles.buttonGroup}>
              <Button onClick={handleClickAccept} rightIcon={<ArrowRightIcon />}>
                {t('cookie.accept.button')}
              </Button>
              <Button variant="secondary" onClick={handleClickReadMore}>
                {t('cookie.read.more.button')}
              </Button>
            </div>
          </div>
        ),
        closeIcon: null,
        duration: 0,
        placement: 'bottom',
        style: styles,
        key: messageKey,
      };
      notification.open(props);
    }
  }, [t, navigate]);

  return null;
}
